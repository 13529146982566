import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'
import useSiteMetadata from '@helpers/useSiteMetadata'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => {
  const { topic } = useSiteMetadata()

  return (
    <>
      <Heading variant='h1'>
        Buy a <Text sx={styles.author}>{topic}</Text>
      </Heading>
      <Heading variant='h1' sx={styles.occupation}>
        Franchise?
      </Heading>
      <Heading variant='h3' sx={styles.specialty}>
        We can help you choose the right one
      </Heading>
      <Box variant='buttons.group'>
        {/* <Button as={Link} to='/contact/'>
          Contact Us
        </Button>
        <Button variant='white' as={Link} to='/about/'>
          About Us
        </Button> */}
        <Button as={Link} to={`/franchise-category/${topic.toLowerCase()}/`}>
          View {topic} Franchises
        </Button>      
      </Box>
    </>
  )
}
